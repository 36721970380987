exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-amazon-web-services-js": () => import("./../../../src/pages/amazon-web-services.js" /* webpackChunkName: "component---src-pages-amazon-web-services-js" */),
  "component---src-pages-android-js": () => import("./../../../src/pages/android.js" /* webpackChunkName: "component---src-pages-android-js" */),
  "component---src-pages-angular-js": () => import("./../../../src/pages/angular.js" /* webpackChunkName: "component---src-pages-angular-js" */),
  "component---src-pages-blog-2-why-is-software-development-outsourcing-better-than-in-house-for-most-businesses-js": () => import("./../../../src/pages/blog2/why-is-software-development-outsourcing-better-than-in-house-for-most-businesses.js" /* webpackChunkName: "component---src-pages-blog-2-why-is-software-development-outsourcing-better-than-in-house-for-most-businesses-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-contact-join-us-js": () => import("./../../../src/pages/contact-join-us.js" /* webpackChunkName: "component---src-pages-contact-join-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-form-landing-js": () => import("./../../../src/pages/form-landing.js" /* webpackChunkName: "component---src-pages-form-landing-js" */),
  "component---src-pages-free-consult-js": () => import("./../../../src/pages/free-consult.js" /* webpackChunkName: "component---src-pages-free-consult-js" */),
  "component---src-pages-graphql-js": () => import("./../../../src/pages/graphql.js" /* webpackChunkName: "component---src-pages-graphql-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iphone-app-development-js": () => import("./../../../src/pages/iphone-app-development.js" /* webpackChunkName: "component---src-pages-iphone-app-development-js" */),
  "component---src-pages-node-js": () => import("./../../../src/pages/node.js" /* webpackChunkName: "component---src-pages-node-js" */),
  "component---src-pages-phonegap-js": () => import("./../../../src/pages/phonegap.js" /* webpackChunkName: "component---src-pages-phonegap-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-processing-of-personal-data-js": () => import("./../../../src/pages/processing-of-personal-data.js" /* webpackChunkName: "component---src-pages-processing-of-personal-data-js" */),
  "component---src-pages-react-js": () => import("./../../../src/pages/react.js" /* webpackChunkName: "component---src-pages-react-js" */),
  "component---src-pages-react-native-js": () => import("./../../../src/pages/react-native.js" /* webpackChunkName: "component---src-pages-react-native-js" */),
  "component---src-pages-recruitment-agreement-js": () => import("./../../../src/pages/recruitment-agreement.js" /* webpackChunkName: "component---src-pages-recruitment-agreement-js" */),
  "component---src-pages-recruitment-consents-js": () => import("./../../../src/pages/recruitment-consents.js" /* webpackChunkName: "component---src-pages-recruitment-consents-js" */),
  "component---src-pages-ruby-on-rails-js": () => import("./../../../src/pages/ruby-on-rails.js" /* webpackChunkName: "component---src-pages-ruby-on-rails-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-templates-client-js": () => import("./../../../src/templates/client.js" /* webpackChunkName: "component---src-templates-client-js" */),
  "component---src-templates-job-offer-js": () => import("./../../../src/templates/job-offer.js" /* webpackChunkName: "component---src-templates-job-offer-js" */),
  "component---src-templates-post-blog-js": () => import("./../../../src/templates/post-blog.js" /* webpackChunkName: "component---src-templates-post-blog-js" */)
}

