/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.css';

export { wrapRootElement } from './src/apollo/wrap-root-element';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

export const onClientEntry = () => {
  const consentGiven = getCookie('accepts-cookies') === 'true' ? 'granted' : 'denied';
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() { window.dataLayer.push(arguments); };
  window.gtag('consent', 'default', {
    ad_user_data: consentGiven,
    ad_personalization: consentGiven,
    ad_storage: consentGiven,
    analytics_storage: consentGiven,
  });
};
